:root {
    --primary: #fff;
    --secondary: #d44edb;
}

.btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
}

.btn--outline {
    background-color: transparent;
    color: var(--primary);
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
}

.btn--secondary {
    background-color: var(--secondary);
    color: #fff;
    border: 1px solid var(--secondary);
}


.btn--medium{
    padding: 8px 20px;
    font-size: 20px; 
}

.btn--large {
    padding: 12px 26px;
    font-size: 20px;
}

.btn--xlarge{
    padding: 12px 26px;
    font-size: 20px;
    width: 100%;
}

.btn--medium:hover, .btn--large:hover{
    background: var(--primary);
    color: #242424;
    transition: all 0.3s ease-out;
}

.btn--secondary:hover {
    border: 1px solid var(--secondary);
    background-color: #fff;
    color: #242424;
    transition: all 0.3s ease-out;
}