.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 25%);

    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-inner {
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 640px;
    background-color: #fff;
    text-align: center;
    border-radius: 10px;
}

.popup-inner .close-btn {
    position: absolute;
    top: 10px;
    right: 16px;
    border: none;
    background: #fff;
    font-size: 2rem;
    color: #757575;
    cursor: pointer;
}

.popup-inner h2 {
    font-family: 'Open Sans', sans-serif;
}

.popup-inner p {
    color: #757575;
    font-size: 1.25rem;
    font-family: 'Open Sans', sans-serif;
}

.fa-envelope::before {
    font-size: 8rem;
    color: #99d06d;
}

.popup-heading {
    color: #99d06d;
    font-size: 3rem;
}

.popup-btn {
    padding: 12px 26px;
    font-size: 20px;
    background-color: #d44edb;
    color: #fff;
    border: 1px solid #d44edb;
    border-radius: 2px;
    width: 40%;
}

.popup-btn:hover {
    border: 1px solid #d44edb;
    background-color: #fff;
    color: #242424;
    transition: all 0.3s ease-out;
    cursor: pointer;
}